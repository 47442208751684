<template>
    <div class="wrapper">
        <Header :code="viewForm.projectCode" :title="title" :banner="banner"></Header>
        <div class="content">
            <div class="main-content">
                <div class="breadcrumb">
                    <Breadcrumb>
                        <BreadcrumbItem @click.native="goHome(viewForm.projectCode)">招聘职位</BreadcrumbItem>
                        <BreadcrumbItem>职位详情</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div class="job-detail">
                    <div class="job-basic">
                        <Row :gutter="16" align="middle">
                            <Col class="job-info" span="20">
                                <div class="job-name">{{ viewForm.name }}<span class="salary">{{ viewForm.salaryFrom === -1 ? '面议' : (viewForm.salaryFrom + 'K - ' + viewForm.salaryTo + 'K') }}</span></div>
                                <div class="job-location">{{ viewForm.cityName }}</div>
                                <div class="job-keywords"><Tag>{{ viewForm.experienceName }}</Tag><Tag>{{ viewForm.highestDegreeName }}</Tag></div>
                            </Col>
                            <Col class="apply-btn" span="4">
                                <Button type="primary" size="large" shape="circle" icon="ios-paper-plane" v-if="phone != viewForm.phone" @click="handleApplication">去报名</Button>
                            </Col>
                        </Row>
                    </div>
                    <div class="job-headline">职位职责</div>
                    <div class="job-detail-block">
                        <div class="multiline-content" v-html="viewForm.responsibility"></div>
                    </div>
                    <div class="job-headline">任职要求</div>
                    <div class="job-detail-block">
                        <div class="multiline-content" v-html="viewForm.requirements"></div>
                    </div>
                    <div class="job-headline">福利待遇</div>
                    <div class="job-detail-block">
                        <div class="multiline-content" v-html="viewForm.benefits"></div>
                    </div>
                    <div class="job-headline" v-if="viewForm.deptName!=null && viewForm.deptName!=''">招聘部门</div>
                    <div class="job-detail-block" v-if="viewForm.deptName!=null && viewForm.deptName!=''">
                        <p>{{ viewForm.deptName }}</p>
                    </div>
                    <div class="job-headline" v-if="viewForm.positionNumber!=null && viewForm.positionNumber!=''">招聘数量</div>
                    <div class="job-detail-block" v-if="viewForm.positionNumber!=null && viewForm.positionNumber!=''">
                        <p>{{ viewForm.positionNumber }}人</p>
                    </div>
                    <div class="job-headline" v-if="viewForm.otherRequirement!=null && viewForm.otherRequirement!=''">其他要求</div>
                    <div class="job-detail-block" v-if="viewForm.otherRequirement!=null && viewForm.otherRequirement!=''">
                        <div class="multiline-content" v-html="viewForm.otherRequirement"></div>
                    </div>
                    <div class="job-headline" v-if="viewForm.profile!=null && viewForm.profile!=''">公司简介</div>
                    <div class="job-detail-block" v-if="viewForm.profile!=null && viewForm.profile!=''">
                        <div class="multiline-content" v-html="viewForm.profile"></div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <Login v-model="showLoginModal" :code="code"></Login>
    </div>
</template>

<script>
import {getSendNumberByPhone,getPositionByCode} from "../../api/client-api";
import Header from "../../components/pc/Header";
import Footer from "../../components/pc/Footer";
import Login from "../../views/pc/login";

export default {
    components: {Header, Footer, Login},
    data() {
        return {
            banner: null,
            title: null,
            phone: '',
            code: '',
            showLoginModal: false,
            positionCode: null,
            viewForm:{}
        }
    },
    methods: {
        getJobDetail(){
            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: true,
                loader: 'bars',
                width: 48,
                height: 48,
                color:'#00BBBD',
            })
            getPositionByCode({code: this.positionCode}).then((res)=>{
                loader.hide();
                if (res.data.data) {
                    this.viewForm = res.data.data
                    if (this.positionCode === null || this.positionCode === '') {
                        this.$router.push({name: 'positionPrompt', query: {projectCode: this.viewForm.projectCode,positionCode: this.positionCode}});
                    }else if (this.viewForm.status !== 1){
                        this.$router.push({name: 'positionPrompt', query: {projectCode: this.viewForm.projectCode,positionCode: this.positionCode}});
                    }
                    this.title = this.viewForm.companyName + this.viewForm.name
                    this.banner = this.viewForm.banner
                } else {
                    this.$router.push({name: 'positionPrompt', query: {projectCode: this.viewForm.projectCode,positionCode: this.positionCode}});
                }
            }).catch(()=>{
                this.viewForm = {}
            })
        },
        goHome(code) {
            this.$router.push({name:'project', params:{id: code}, query: {tab: 'position'}});
        },
        handleApplication() {
            if (sessionStorage.getItem('Authorization')) {
                getSendNumberByPhone({phone: this.phone}).then((res)=>{
                    if (res.data.code === 1){
                        let counts = 0
                        let list = []
                        for (let i = 0; i < res.data.data.length; i++) {
                            list.push(res.data.data[i].code)
                            res.data.data[i].counts = res.data.data[i].counts - 0
                            if (res.data.data[i].projectCode === this.viewForm.projectCode) {
                                counts = counts + res.data.data[i].counts
                            }
                        }
                        if (counts >= this.viewForm.remark && this.viewForm.remark > 0) {
                            this.$Message.warning("已达到本项目最多投递数量")
                        } else if (list.includes(this.positionCode)) {
                            this.$Message.warning("您已投递过该职位")
                        } else {
                            this.$router.push({name: 'application', query: {projectCode: this.viewForm.projectCode, positionCode: this.positionCode}});
                        }
                    }
                }).catch(()=>{
                    console.log("err")
                })
            } else {
                this.code = this.viewForm.projectCode
                this.showLoginModal = true;
            }
        }
    },
    watch: {
        '$route.params.id': {
            handler: function (newVal, oldVal){
                this.phone = sessionStorage.getItem('un')
                this.positionCode = newVal;
                this.getJobDetail()
            },
            immediate: true
        }
    },
    mounted() {
    }
};
</script>

<style scoped>

</style>
