<template>
    <div class="wrapper has_nav_bar">
        <van-nav-bar title="职位详情" @click-left="goHome" left-text="返回" left-arrow fixed>
            <template #right>
                <van-icon name="share-o" size="16" @click="onSelect" />
            </template>
        </van-nav-bar>
        <div class="content">
            <div class="main-content">
                <div class="job-detail">
                    <div class="job-basic">
                        <div class="job-info">
                            <div class="job-name">{{ viewForm.name }}<span class="salary">{{ viewForm.salaryFrom === -1 ? '面议' : (viewForm.salaryFrom + 'K - ' + viewForm.salaryTo + 'K') }}</span></div>
                            <div class="job-location">{{ viewForm.cityName }}</div>
                            <div class="job-keywords"><van-tag plain>{{ viewForm.experienceName }}</van-tag><van-tag plain>{{ viewForm.highestDegreeName }}</van-tag></div>
                        </div>
                    </div>
                    <div class="job-headline">职位职责</div>
                    <div class="job-detail-block" v-html="viewForm.responsibility"></div>
                    <div class="job-headline">任职要求</div>
                    <div class="job-detail-block"  v-html="viewForm.requirements"></div>
                    <div class="job-headline">福利待遇</div>
                    <div class="job-detail-block" v-html="viewForm.benefits"></div>
                    <div class="job-headline" v-if="viewForm.deptName!=null && viewForm.deptName!=''">招聘部门</div>
                    <div class="job-detail-block" v-if="viewForm.deptName!=null && viewForm.deptName!=''">
                        <p>{{ viewForm.deptName }}</p>
                    </div>
                    <div class="job-headline" v-if="viewForm.positionNumber!=null && viewForm.positionNumber!=''">招聘数量</div>
                    <div class="job-detail-block" v-if="viewForm.positionNumber!=null && viewForm.positionNumber!=''">
                        <p>{{ viewForm.positionNumber }}人</p>
                    </div>
                    <div class="job-headline" v-if="viewForm.otherRequirement!=null && viewForm.otherRequirement!=''">其他要求</div>
                    <div class="job-detail-block" v-if="viewForm.otherRequirement!=null && viewForm.otherRequirement!=''">
                        <div class="multiline-content" v-html="viewForm.otherRequirement"></div>
                    </div>
                    <div class="job-headline" v-if="viewForm.profile!=null && viewForm.profile!=''">公司简介</div>
                    <div class="job-detail-block" v-if="viewForm.profile!=null && viewForm.profile!=''">
                        <div class="multiline-content" v-html="viewForm.profile"></div>
                    </div>
                    <div class="apply-btn">
                        <van-button type="primary" block v-if="phone != viewForm.phone" @click="handleApplication">报名</van-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
        </div>
    </div>
</template>

<script>
import {getPositionByCode, getSendNumberByPhone} from "../../api/client-api";

export default {
    data() {
        return {
            showShare: false,
            banner: null,
            title: null,
            phone: '',
            code: '',
            showLoginModal: false,
            positionCode: null,
            viewForm:{}
        }
    },
    methods: {
        onSelect() {
            var _input = document.createElement('input')
            _input.value = window.location.href
            document.body.appendChild(_input)
            _input.select()
            document.execCommand('Copy')
            this.$notify({type: 'success', duration: '1000', message: '复制链接成功'});
            _input.remove()
        },
        getJobDetail(){
            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: true,
                loader: 'bars',
                width: 48,
                height: 48,
                color:'#00BBBD',
            })
            getPositionByCode({code: this.positionCode}).then((res)=>{
                loader.hide();
                if (res.data.data) {
                    this.viewForm = res.data.data
                    if (this.positionCode === null || this.positionCode === '') {
                        this.$router.push({name: 'positionPrompt', query: {projectCode: this.viewForm.projectCode,positionCode: this.positionCode}});
                    }else if (this.viewForm.status !== 1){
                        this.$router.push({name: 'positionPrompt', query: {projectCode: this.viewForm.projectCode,positionCode: this.positionCode}});
                    }
                    this.title = this.viewForm.companyName + this.viewForm.name
                    this.banner = this.viewForm.banner
                } else {
                    this.$router.push({name: 'positionPrompt', query: {projectCode: this.viewForm.projectCode,positionCode: this.positionCode}});
                }
            }).catch(()=>{
                this.viewForm = {}
            })
        },
        goHome() {
            this.$router.push({name: 'project', params: {id: this.viewForm.projectCode}, query: {tab: 'job-list'}});
        },
        handleApplication() {

            if (sessionStorage.getItem('Authorization')) {
                getSendNumberByPhone({phone: this.phone}).then((res)=>{
                    if (res.data.code === 1){
                        let counts = 0
                        let list = []
                        for (let i = 0; i < res.data.data.length; i++) {
                            list.push(res.data.data[i].code)
                            res.data.data[i].counts = res.data.data[i].counts - 0
                            if (res.data.data[i].projectCode === this.viewForm.projectCode) {
                                counts = counts + res.data.data[i].counts
                            }
                        }
                        if (counts >= this.viewForm.remark && this.viewForm.remark > 0) {
                            this.$notify({type: 'danger', duration: '1000', message: '已达到本项目最多投递数量'});
                        } else if (list.includes(this.positionCode)) {
                            this.$notify({type: 'danger', duration: '1000', message: '您已投递过该职位'});
                        } else {
                            this.$router.push({name: 'application', query: {projectCode: this.viewForm.projectCode, positionCode: this.positionCode, backTo: 'position'}});
                        }
                    }
                }).catch(()=>{
                    console.log("err")
                })
            } else {
                let redirectTo = window.location.href;
                sessionStorage.setItem('redirectTo', redirectTo);
                this.$router.push({name:'login'});
            }
        },
    },
    watch: {
        '$route.params.id': {
            handler: function (newVal, oldVal){
                this.phone = sessionStorage.getItem('un')
                this.positionCode = newVal;
                this.getJobDetail()
            },
            immediate: true
        }
    },
    mounted() {
    }
};
</script>

<style scoped>

</style>
